var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('shop.article.group', 100),"items":_vm.articleGroups,"headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultArticleGroupItem,"crudPermissionSubject":"ArticleGroup","crudURLPrefix":"/admin/article/group"},on:{"crudDialogOpen":_vm.crudDialogOpened,"crudDialogSave":function (updatedItems) { return (_vm.articleGroups = updatedItems); }},scopedSlots:_vm._u([{key:"item.names",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.names.map(function (langCodeName) { return langCodeName.name; }).join(", "))+" ")]}},{key:"crud-form",fn:function(ref){
var item = ref.item;
return [_c('AdminLangCodeNameTable',{attrs:{"editable":"","langCodeNames":item.names},on:{"crudDialogSave":function (updatedLangCodeNames) { return (item.names = updatedLangCodeNames); }}}),_c('v-switch',{attrs:{"label":((_vm.$t('shop.article.group.childGroup')) + "?: " + (_vm.isChildGroup ? _vm.$t('label.yes') : _vm.$t('label.no')))},on:{"change":function (value) {
                    item.parentGroupId = null;
                    item.categories = [];
                }},model:{value:(_vm.isChildGroup),callback:function ($$v) {_vm.isChildGroup=$$v},expression:"isChildGroup"}}),(_vm.isChildGroup)?_c('v-select',{attrs:{"label":_vm.$t('shop.article.group.parentGroup'),"items":_vm.articleGroups,"item-text":"names[0].name","item-value":function (group) { return group.id; },"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.parentGroupId),callback:function ($$v) {_vm.$set(item, "parentGroupId", $$v)},expression:"item.parentGroupId"}}):_c('v-select',{attrs:{"label":_vm.$tc('shop.article.category'),"multiple":"","items":_vm.articleCategories,"item-text":"names[0].name","item-value":function (category) { return category; },"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.categories),callback:function ($$v) {_vm.$set(item, "categories", $$v)},expression:"item.categories"}}),_c('v-text-field',{attrs:{"label":_vm.$t('shop.article.idAdditive')},model:{value:(item.idAdditive),callback:function ($$v) {_vm.$set(item, "idAdditive", $$v)},expression:"item.idAdditive"}}),_c('v-file-input',{attrs:{"value":item.image != undefined ? item.image.fileObject : null,"label":_vm.$t('image.fileObject'),"accept":"image/png, image/jpeg","clearable":"","show-size":""},on:{"change":function (image) {
                    if (image == null) {
                        item.image = null;
                    } else {
                        item.image = {
                            id: -1,
                            src: '',
                            fileObject: image,
                        };
                    }
                }}}),(item.image !== null)?_c('v-text-field',{attrs:{"label":_vm.$t('image.alt')},model:{value:(item.image.alt),callback:function ($$v) {_vm.$set(item.image, "alt", $$v)},expression:"item.image.alt"}}):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }