































































































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { ArticleGroup, ArticleCategory } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import AdminLangCodeNameTable from "@/components/admin/language/name/AdminLangCodeNameTable.vue";

/**
 * AdminShopArticleGroupTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        AdminLangCodeNameTable,
    },
})
export default class AdminShopArticleGroupTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private isChildGroup = false;

    private articleGroups: ArticleGroup[] = [];
    private articleCategories: ArticleCategory[] = [];

    private defaultArticleGroupItem: ArticleGroup = {
        id: -1,
        parentGroupId: null,
        children: [],
        categories: [],
        names: [],
        idAdditive: "",
        image: null,
        sales: 0,
        orderCount: 0,
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("language.name").toString(),
                value: "names",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            [this.articleGroups, this.articleCategories] = await Promise.all([
                this.fetchArticleGroups(),
                this.fetchArticleCategories(),
            ]);
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Function will be called when crud dialog was opened
     * Sets isChildGroup
     *
     * @param dialogMode dialog mode
     * @param crudItem current crud item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private crudDialogOpened(dialogMode: string, crudItem: ArticleGroup) {
        if (dialogMode === "create") return;

        this.isChildGroup = crudItem.parentGroupId !== null;
    }

    /**
     * Fetches all article groups
     *
     * @returns Promise<ArticleGroup[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleGroups(): Promise<ArticleGroup[]> {
        const response = (
            await axios.get<APIResponse<ArticleGroup[]>>(
                "/admin/articles/groups"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all article groups
     *
     * @returns Promise<ArticleGroup[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleCategories(): Promise<ArticleCategory[]> {
        const response = (
            await axios.get<APIResponse<ArticleGroup[]>>("/articles/categories")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
