






import { Component, Vue } from "vue-property-decorator";
import AdminShopArticleGroupTable from "@/components/admin/shop/article/group/AdminShopArticleGroupTable.vue";

/**
 * AdminShopArticleGroupManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminShopArticleGroupTable,
    },
})
export default class AdminShopArticleGroupManagement extends Vue {}
